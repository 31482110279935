import React, { FunctionComponent, useEffect } from 'react'
import { AuthState } from '@aws-amplify/ui-components'
import { Redirect, useLocation } from 'react-router-dom'
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'

const Auth:FunctionComponent<any> = (props) => {
  const { authState, user } = props
  const location = useLocation()
  const { from }:any = location.state || { from: { pathname: '/portal/' } }

  const isSmallScreen = window.innerWidth < 768

  useEffect(() => {
    if (isSmallScreen) {
      document.body.classList.add("p-0")
    }
  }, [isSmallScreen])

  return (
    authState === AuthState.SignedIn && user
      ? (<Redirect to={from.pathname} />)
      : (
        <div className={'authenticator-custom'}>
          <AmplifyAuthenticator>
            <AmplifySignIn
              headerText={'Login'}
              hideSignUp
              slot={'sign-in'}
              usernameAlias={'email'}
            />
          </AmplifyAuthenticator>
        </div>
      )
  )
}

export default Auth
