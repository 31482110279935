import React, { FunctionComponent } from 'react'
import TopBarSideBarLogo from './components/TopBarSideBarLogo'
import LeftTopBar from './containers/LeftTopBar'
import RightTopBar from './containers/RightTopBar'

const TopBar: FunctionComponent<{
  toggleMenu: () => void,
}> = (props) => {
  const { toggleMenu } = props

  return (
    <div className={'navbar-custom'}>
      <LeftTopBar />
      <TopBarSideBarLogo />
      <RightTopBar
        toggleMenu={toggleMenu}
      />
    </div>
  )
}

export default TopBar
