import React, { FunctionComponent } from 'react'
import { Col, Row } from 'reactstrap'
import DealsList from './List'
import DealsSingle from './Single'

const DealsMain: FunctionComponent = () => {
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="page-title-box">
            <h4 className="page-title">Transactions</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={8} className={'order-sm-1 order-xs-2'}>
          <DealsList />
        </Col>
        <Col xl={4} className={'order-sm-2 order-xs-1'}>
          <DealsSingle />
        </Col>
      </Row>
    </>
  )
}

export default DealsMain
