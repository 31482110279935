import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { Card, CardBody, Row } from 'reactstrap'
import { useHistory, useRouteMatch } from 'react-router-dom'
import DealsListFilter from './list-components/Filter'
import { useLoadDeals } from '../../common/hooks/useLoadDeals'
import AlertMessage from '../../common/components/AlertMessage'
import Pager from '../../common/components/Pager'
import { PagerProps } from '../../common/models/pager'
import DealListItemContainer from './list-components/ItemContainer'
import SummaryHeader from './list-components/SummaryHeader'
import { useLoadUnreadDeals } from '../../common/hooks/useLoadUnreadDeals'

const DealsList: FunctionComponent = () => {
  const {
    clearError, count, filters, isSubmitting, data: listData,
    error, pager, summaryData, updateFilters, updatePager
  } = useLoadDeals()
  const { data: unreadData, reqBody: unreadDealsReqBody, updateReqBody: unreadDealsUpdateReqBody } = useLoadUnreadDeals()
  const history = useHistory()
  const rm = useRouteMatch<any>()
  const currentId = parseInt(rm?.params?.id) || null
  const selectedTenantName = rm?.params?.brand || null

  const directToClp = (id: number, brand: string) => {
    history.push(`/portal/transactions/${brand}/${id}`)
  }

  const submitFilters = (filters:any) => {
    updateFilters(filters)
  }

  const submitPager = (pager: PagerProps) => {
    updatePager(pager)
  }

  const requestUnreadDeals = useCallback(() => {
    const newReq = listData.map(deal => { return {clpId: deal.id, tenant: deal.tenant.tenantName}})
    if (JSON.stringify(newReq) !== JSON.stringify(unreadDealsReqBody)) {
      unreadDealsUpdateReqBody(newReq)
    }
  }, [listData, unreadDealsReqBody, unreadDealsUpdateReqBody])

  useEffect(() => {
    if (listData.length > 0) {
      requestUnreadDeals()
    }
  }, [listData, requestUnreadDeals])

  return (
    <>
      { filters?.onsetStatusIn?.includes('Pending') && filters?.onsetStatusIn?.length === 1
        ? <SummaryHeader count={count} summaryData={summaryData} />
        : null
      }
      <Card>
        {error
          ? (
            <AlertMessage
              color={'danger'}
              message={'There has been an issue with loading your list, please refresh and try again or contact support if the issue persists.'}
              onClose={clearError}
            />
          )
          : null
        }
        <CardBody>
          <Row className={'mb-2'}>
            <DealsListFilter dataLoading={isSubmitting} submitFilters={submitFilters} />
          </Row>
          <div className={'table-responsive table-overflow-y-hidden'}>
            <table className={'table table-centered table-hover mb-0'}>
              <thead className={'thead-light'}>
                <tr>
                  <th>Company Name</th>
                  <th>Received</th>
                  <th>Due Diligence</th>
                  <th>Requested</th>
                  <th>Status</th>
                  <th>Approved</th>
                  <th>Brand</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <DealListItemContainer
                  currentId={currentId}
                  isSubmitting={isSubmitting}
                  listData={listData}
                  navigationClick={directToClp}
                  unreadDeals={unreadData}
                  selectedTenantName={selectedTenantName}
                />
              </tbody>
            </table>
          </div>
          <Pager count={count} pager={pager} updatePager={submitPager}/>
        </CardBody>
      </Card>
    </>
  )
}

export default DealsList
