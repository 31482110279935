import React, { FunctionComponent } from 'react'
import { DealBase } from '../../../common/models/credit-lender-package'
import DealsListItem from './Item'
import { UnreadDealPresentProps } from '../../../common/models/deals'

interface Props {
  currentId: number | null
  isSubmitting: boolean
  listData: Array<DealBase> | []
  navigationClick: (id: number, brand: string) => void,
  selectedTenantName: string | null
  unreadDeals: Array<UnreadDealPresentProps>
}

// NOTE: Could be solved better with Component composition and possible make some parts more generic
const DealListItemContainer: FunctionComponent<Props> = (props) => {
  const { currentId, isSubmitting, listData, navigationClick, selectedTenantName, unreadDeals } = props

  if (isSubmitting) {
    return (
      <tr>
        <td colSpan={8} className={'text-center loading-table-row'}>
          <div className="spinner-border m-2" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </td>
      </tr>
    )
  }

  if (listData.length === 0) {
    return (
      <tr>
        <td colSpan={8} className={'text-center loading-table-row'}>
          <span>No Transactions to show with these filter options, adjust the filters to be more open or contact support if you believe this to be in error.</span>
        </td>
      </tr>
    )
  }

  const iterableArray: Array<DealBase> = listData
  const rows = iterableArray.map((item, idx) => {
    const hasUnread = unreadDeals.filter(deal => parseInt(deal.id) === item.id && (deal.hasUnreadDocs || deal.hasUnread) && item.tenant.tenantName === deal.tenantName)
    return (
      <DealsListItem
        key={idx}
        currentId={currentId}
        hasUnread={hasUnread.length > 0}
        navigationClick={navigationClick}
        simpleDeal={item}
        selectedTenantName={selectedTenantName}
      />
    )}
  )

  return (
    <>
      {rows}
    </>
  )
}

export default DealListItemContainer
