import React, { FunctionComponent } from 'react'
import { Col, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import { Link } from 'react-router-dom'

const NavMegaMenu: FunctionComponent = () => {
  // NOTE: Unused component for future expansion.
  return (
    <UncontrolledDropdown className={'dropdown dropdown-mega d-none d-lg-block'}>
      <DropdownToggle
        tag={'button'}
        className={'btn btn-link nav-link dropdown-toggle waves-effect waves-light'}
      >
        Mega Menu
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu className={'dropdown-menu dropdown-megamenu'}>
        <Row>
          <Col sm={8}>
            <Row>
              <Col md={4}>
                <h5 className="text-dark mt-0">Main Pages</h5>
                <ul className="list-unstyled megamenu-list">
                  <li>
                    <Link to="/error">No Sub Pages Added.</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NavMegaMenu

