import React, { FunctionComponent, useState } from 'react'
import { Col } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { onsetStatuses } from '../../../common/utils/constants'

interface Props {
  dataLoading: boolean,
  submitFilters: (filters: any) => void,
}

const DealsListFilter: FunctionComponent<Props> = (props) => {
  const { dataLoading, submitFilters } = props
  const [isShown, setIsShown] = useState(false)

  const ALL_OPTION = 'All'

  // TODO: Pull in filter defaults from the Filter in useLoadDeals hook.
  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      companyNameLike: '',
      dateSentLt: '',
      dateSentGt: '',
      onsetStatusIn: 'Pending',
    }
  })

  const { register, formState, handleSubmit, reset } = formMethods
  const { isSubmitting, isDirty } = formState

  const onSubmit = (values: any) => {
    const { onsetStatusIn, ...theRest } = values

    let onsetStatusFilter

    if (onsetStatusIn === ALL_OPTION) {
      onsetStatusFilter = Object.entries(onsetStatuses).map(([key, value]) => value)
    } else {
      onsetStatusFilter = [onsetStatusIn]
    }

    const filters = {
      ...theRest,
      onsetStatusIn: onsetStatusFilter
    }
    submitFilters(filters)
    reset(values)
  }

  const { PENDING, ...theRest } = onsetStatuses

  let onsetStatusOptions = [
    (<option key={-2} value={ALL_OPTION}>{ALL_OPTION}</option>),
    (<option key={-1} value={PENDING}>{PENDING}</option>),
    ...(Object.entries(theRest).map(([key, value], index) => (
      <option key={index} value={value}>{value}</option>
    )))]

  // NOTE: Using manual select due to React Select not being updated to React 17
  return (
    <>
      <form className="form-inline w-100">
        <Col className={'form-inline'} sm={8} md={8}>
          <div className="form-group mb-2 mr-2">
            <label htmlFor="search-string" className="sr-only">Search</label>
            <input
              id="search-string"
              className="form-control"
              name={'companyNameLike'}
              placeholder="Search..."
              ref={register}
              type="search"
            />
          </div>
          <div className="form-group mb-2 ml-2">
            <label htmlFor="onset-status" className={'mr-1'}>Status:</label>
            <select id={'onset-status'} className="custom-select" name={'onsetStatusIn'} ref={register}>
              {onsetStatusOptions}
            </select>
          </div>
        </Col>
        <Col sm={4} md={4}>
          <div className={`d-flex justify-content-end align-items-center`}>
            <div className={`flex-column align-items-end mr-2 ${isShown ? 'd-flex' : 'd-none'}`}>
              <div className="form-group mb-2">
                <label htmlFor={'date-sent-gt'} className={'mr-1'}>Date Sent After</label>
                <input
                  id={'date-sent-gt'}
                  className={'form-control'}
                  name={'dateSentGt'}
                  ref={register}
                  type={'date'}
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor={'date-sent-lt'} className={'mr-1'}>Date Sent Before</label>
                <input
                  id={'date-sent-lt'}
                  className={'form-control'}
                  name={'dateSentLt'}
                  ref={register}
                  type={'date'}
                />
              </div>
            </div>
            <button type={'button'} className={'btn btn-info waves-effect waves-light mb-2 mr-1'} onClick={() => setIsShown(!isShown)}>
              <i className={'mdi mdi-filter'} />
            </button>
            <button
              type="submit"
              disabled={isSubmitting || dataLoading || !isDirty}
              className={`btn ${isDirty ? 'btn-success' : 'btn-secondary'} waves-effect waves-light mb-2 mr-1`}
              onClick={handleSubmit(onSubmit)}
            >
              Apply
              {dataLoading
                ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm mr-1 ml-2 loading-button-spinner" role="status" aria-hidden="true" />
                    <span className="sr-only">Loading...</span>
                  </>
                )
                : null
              }
            </button>
          </div>
        </Col>
      </form>
    </>
  )
}

export default DealsListFilter
