import { useCallback, useEffect, useState } from 'react'
import { UnreadDealPresentProps } from '../models/deals'
import { getUnreadDealDocumentsPresent, getUnreadDealPresent } from '../services/api'

interface UnreadDealId {
  clpId: number
  tenant: string
}

export function useLoadUnreadDeals () {
  const [data, setData] = useState<Array<UnreadDealPresentProps>>([])
  const [error, setError] = useState<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reqBody, setReqBody] = useState<Array<UnreadDealId>>([])

  const loadDealUnreads = useCallback(async () => {
    setIsSubmitting(true)
    setError(null)
    setData([])

    try {
      if (reqBody.length > 0) {
        const respUnreadDeals = await Promise.all([
          ...reqBody.map((deal) => getUnreadDealPresent(deal.clpId, deal.tenant)),
          ...reqBody.map((deal) => getUnreadDealDocumentsPresent(deal.clpId, deal.tenant))
        ])
        if (respUnreadDeals.length > 0) {
          setData(respUnreadDeals.map(deal => {
            // NOTE: Not the biggest fan of this, but I want to discuss some other thoughts around it.
            const tenantName = new URLSearchParams(deal.url.substring(deal.url.indexOf('?'))).get('tenantName')
            return {
              tenantName,
              ...deal.body.data,
            }
          }))
        }
      }
    } catch (e) {
      setError({ message: 'There was an issue loading unread deals.', errorBody: e })
    } finally {
      setIsSubmitting(false)
    }
  }, [reqBody, setData, setError, setIsSubmitting])

  useEffect(() => {
    if (reqBody) {
      loadDealUnreads().then()
    }
  }, [loadDealUnreads, reqBody])

  const updateReqBody = useCallback((clpsRequested) => {
    if (clpsRequested.length > 0 && clpsRequested !== reqBody) {
      setReqBody(clpsRequested)
    }
  }, [reqBody, setReqBody])

  return {
    data,
    error,
    isSubmitting,
    reqBody,
    updateReqBody,
  }
}
