import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { ErrorBoundary } from './common/components/ErrorBoundary'
import Main from './pages/Main'
import Auth from './pages/login/Auth'
import PrivateRoute from './common/components/PrivateRoute'
import { UserContext } from './common/contexts/user'

// Default App CSS
import './App.css'

function App() {
  const [ authState, setAuthState ] = useState<AuthState>()
  const [ user, setUser ] = useState<object | undefined>()

  useEffect(() => {
    onAuthUIStateChange(((nextAuthState, data) => {
      setAuthState(nextAuthState)
      setUser(data)
    }))
  }, [])

  return (
    <ErrorBoundary>
      <div id={'wrapper'} className={'wrapper'}>
        <Router basename={'/'} forceRefresh={false}>
          <Switch>
            <PrivateRoute
              authState={authState}
              path={['/portal/']}
              routeChildren={(routeProps: any) => (
                <UserContext.Provider value={user}>
                  <Main {...routeProps} />
                </UserContext.Provider>
              )}
              user={user}
            />
            <Route
              exact
              path={'/login'}
            >
              <Auth authState={authState} user={user} />
            </Route>
            <Route
              exact
              path={'/*'}
              render={() => <Redirect to={{ pathname: '/portal/'}} />}
            />
          </Switch>
        </Router>
      </div>
    </ErrorBoundary>
  )
}

export default App
