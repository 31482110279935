import { StepPayment } from '../models/step-payment'
import DateUtils from './date-utils'

const StepPaymentUtils = {
  groupByObjProp: function (arr: Array<any>, prop: string) {
    return arr.reduce(function (acc, obj) {
      let key = obj[prop]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  },
  sortSteps: function (stepPayments: Array<StepPayment>) {
    return stepPayments ? stepPayments.sort((a: StepPayment, b: StepPayment) => a.ordinal - b.ordinal).map((step: StepPayment) => {
      const { createdAt, updatedAt, ...theRest} = step
      return {
        ...theRest,
        createdAt: DateUtils.formatDateFromStamp(createdAt),
        updatedAt: DateUtils.formatDateFromStamp(updatedAt),
      }
    }) : []
  },
  separateStepPayments: function (stepPayments: Array<StepPayment>) {
    const { APPROVED, SENT } = this.groupByObjProp(stepPayments, 'stepPaymentType')
    const sortedApproved = this.sortSteps(APPROVED)
    const sortedSent = this.sortSteps(SENT)
    return {
      approvedStepPayments: sortedApproved,
      sentStepPayments: sortedSent,
    }
  }
}

export default StepPaymentUtils
