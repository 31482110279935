import React, { FunctionComponent } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { Link } from 'react-router-dom'

const NavActionDropDown: FunctionComponent = () => {
  // NOTE: Unused component for future expansion.
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="button"
        className="btn btn-link nav-link dropdown-toggle waves-effect waves-light">
        Make Action
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu right className={'dropdown-menu profile-dropdown'}>
        <Link to="/" className="dropdown-item">
          <i className="fe-briefcase mr-1" />
          <span>New Projects</span>
        </Link>
        <Link to="/" className="dropdown-item">
          <i className="fe-user mr-1" />
          <span>Create Users</span>
        </Link>
        <Link to="/" className="dropdown-item">
          <i className="fe-bar-chart-line- mr-1" />
          <span>Revenue Report</span>
        </Link>
        <Link to="/" className="dropdown-item">
          <i className="fe-settings mr-1" />
          <span>Settings</span>
        </Link>
        <DropdownItem divider />
        <Link to="/" className="dropdown-item">
          <i className="fe-headphones mr-1" />
          <span>Help & Support</span>
        </Link>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NavActionDropDown
