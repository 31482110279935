import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthState } from '@aws-amplify/ui-components'

const PrivateRoute: FunctionComponent<any> = (props) => {
  const { authState, routeChildren, user, ...rest } = props

  return (
    <Route
      {...rest}
      children={({location}: any) => {
          return (authState === AuthState.SignedIn && user
            ? (routeChildren())
            : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            )
          )
        }
      }
    />
  )
}

export default PrivateRoute
