import React, { FunctionComponent } from 'react'
import DealSingleApproved from './Approved'
import DealSingleSent from './Sent'
import { DealSingleProps } from '../../../common/models/credit-lender-package'
import { billingCycles, onsetStatusBadgeClasses, onsetStatuses } from '../../../common/utils/constants'
import DateUtils from '../../../common/utils/date-utils'
import numeral from 'numeral'
import DealSingleReasons from './Reasons'
import { ApprovedInfoProps, SentInfoProps, UnreadDealInfoProps } from '../../../common/models/deals'
import DealSingleDocuments from './Documents'
import DealSingleComments from './Comments'

interface Props {
  data: DealSingleProps | null
  unreadDealData: Array<UnreadDealInfoProps> | []
}

const DealSingleContainer: FunctionComponent<Props> = (props) => {
  const { data, unreadDealData } = props
  // NOTE: Unread Deal data contains much more than just showing as having something marked, more can be shown when the request arrives.
  // UNREAD DOCS NOTE: When swapping to useContext, move the DocsUnread to this level and also show the Title Unread as true when at least one unread Doc is present.
  // TODO: Verify number of renders to see if can be optimized more.
  if (!data) {
    return (
      <div className={'d-flex justify-content-center'}>
        There was an issue with the data loaded. Please try refreshing and selecting the Deal in the List to the left. Otherwise please contact support for further assistance.
      </div>
    )
  }

  const { activeBalloon, appConditions, approvedAmount, approvedBillingCycle, approvedCollateral, approvedEnhancement,
    approvedEquipment, approvedLeaseRateFactor, approvedPayment, approvedRate, approvedStepPayments, approvedTerm,
    amountSentOut, balloonAmount, balloonPercentage, billingCycle, collateral, company, dateSent, documents, enhancement,
    equipment, expiration, id, leaseRateFactorSent, nonApprovedReasons, onsetStatus, payment, responseDate,
    sentStepPayments, term, tenant
  } = data
  const badgeClass = onsetStatusBadgeClasses(onsetStatus)
  const dateSentFormatted = DateUtils.formatDateFromStamp(dateSent)

  const sentInfo: SentInfoProps = {
    amountSentOut, collateral, enhancement, equipment, leaseRateFactorSent, payment, sentStepPayments, term,
    dateSent: dateSentFormatted,
    billingCycle: billingCycles[billingCycle]?.name ?? 'N/A',
  }

  const approvedInfo: ApprovedInfoProps = {
    activeBalloon, appConditions, approvedCollateral, approvedEnhancement, approvedEquipment,
    approvedLeaseRateFactor, approvedPayment, approvedRate, approvedStepPayments, approvedTerm,
    balloonAmount, balloonPercentage, expiration,
    approvedBillingCycle: approvedBillingCycle ? billingCycles[approvedBillingCycle].name : 'None Listed',
    responseDate: DateUtils.formatDateFromStamp(responseDate)
  }

  let expandableSections
  if ([onsetStatuses.PENDING, onsetStatuses.RESTRUCTURE].includes(onsetStatus)) {
    expandableSections = (
      <>
        <DealSingleSent defaultOpen={true} hasUnread={unreadDealData.length > 0} sentInfo={sentInfo} isApproved={false} />
      </>
    )
  }
  if ([onsetStatuses.WITHDRAWN, onsetStatuses.WALKED, onsetStatuses.DECLINED, onsetStatuses.HOLD].includes(onsetStatus)) {
    expandableSections = (
      <>
        <DealSingleReasons reasons={nonApprovedReasons} />
        <DealSingleSent defaultOpen={true} sentInfo={sentInfo} hasUnread={unreadDealData.length > 0} isApproved={false} />
      </>
    )
  }
  if ([onsetStatuses.APPROVED_WITH_CHANGES, onsetStatuses.APPROVED].includes(onsetStatus)) {
    expandableSections = (
      <>
        <DealSingleApproved defaultOpen={true} approvedInfo={approvedInfo} hasUnread={unreadDealData.length > 0} />
        <DealSingleSent defaultOpen={false} sentInfo={sentInfo} hasUnread={unreadDealData.length > 0} isApproved />
      </>
    )
  }

  const amount = (onsetStatus === onsetStatuses.APPROVED || onsetStatus === onsetStatuses.APPROVED_WITH_CHANGES)
    ? <p className={'mb-0'}><b>Approved Amount:</b> {numeral(approvedAmount).format('$0,0')}</p>
    : <p className={'mb-0'}><b>Amount sent:</b> {numeral(amountSentOut).format('$0,0')}</p>

  return (
    <>
      <div className={'media mb-3'}>
        <div className={'media-body'}>
          {
            unreadDealData.length > 0
            ? <h4 className={'mt-0 mb-1 position-relative pl-2'}><span className={'unread-light-icon'} /><i className="mdi mdi-office-building" /> {company.name}</h4>
            : <h4 className={'mt-0 mb-1'}><i className="mdi mdi-office-building" /> {company.name}</h4>
          }
          <span className={`mb-1 badge ${badgeClass} badge-pill`}>{onsetStatus}</span>
          {amount}
          <p className={'mb-0'}><b>Date Sent:</b> {dateSentFormatted}</p>
        </div>
      </div>
      {expandableSections}
      <DealSingleComments />
      <DealSingleDocuments
        clpId={id}
        documents={documents}
        dealTenant={tenant}
      />
    </>
  )
}

export default DealSingleContainer
