import React, { FunctionComponent, ReactElement } from 'react'
import { Col, Row } from 'reactstrap'

// BGColor is from the background variants via bootstrap and additional mixins ex 'bg-blue'
// TODO: valueClass is breaking the styling convention, figure out a better way to handle it.
interface Props {
  bgcColor: string
  icon: string
  title?: string
  valueClass: string | null
  value: string | number | ReactElement
}

const SingleValueBox: FunctionComponent<Props> = (props) => {
  const { bgcColor, icon, title, value, valueClass } = props
  return (
    <div className={'card-box bg-pattern'}>
      <Row>
        <Col sm={4}>
          <div className={`avatar-md ${bgcColor} rounded`}>
            <i className={`${icon} avatar-title font-22 text-white`} />
          </div>
        </Col>
        <Col sm={8}>
          <div className={'text-right'}>
            <h3 className={`text-dark my-1 ${valueClass}`}><span>{value}</span></h3>
            { title
              ? <p className={'text-muted mb-0 text-truncate'}>{title}</p>
              : null
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SingleValueBox
