import React, { FunctionComponent } from 'react'
import GlobalSearch from '../components/GlobalSearch'
import LeftTopBarNotifications from '../components/LeftTopBarNotifications'
import LeftTopBarProfile from '../components/LeftTopBarProfile'
import LeftTopBarSettings from '../components/LeftTopBarSettings'

const LeftTopBar: FunctionComponent = () => {
  // NOTE: Global Search, Notifications and Settings are added for future expansion.
  return (
    <ul className="list-unstyled topnav-menu float-right mb-0">
      {/*<li className="d-sm-block">*/}
      <li className="d-none">
        <GlobalSearch />
      </li>
      {/*<li className="dropdown notification-list">*/}
      <li className="d-none">
        <LeftTopBarNotifications />
      </li>
      <li className="dropdown notification-list">
        <LeftTopBarProfile />
      </li>
      {/*<li className="dropdown notification-list">*/}
      <li className="d-none">
        <LeftTopBarSettings />
      </li>
    </ul>
  )
}

export default LeftTopBar
