import React, { Component, ComponentProps } from 'react'

export class ErrorBoundary extends Component<any, any> {
  constructor (props: ComponentProps<any>) {
    super(props)
    this.state = { error: false }
  }
  componentDidCatch (error: Error, errorInfo: React.ErrorInfo): void {
    console.log(error)
    console.log(errorInfo)
    this.setState({
      error: true
    })
  }

  render () {
    const { children } = this.props

    if (this.state.error) return (
      <div className="account-pages mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card bg-pattern">
                <div className="card-body p-4">
                  <div className="text-center mt-4">
                    <h1 className="text-error">500</h1>
                    <h3 className="mt-3 mb-2">Internal Server Error</h3>
                    <p className="text-muted mb-3">There has been an issue with the application, please try refreshing or click the link below to send an email to support.</p>
                    <a className={'text-dark mt-1'} href={'mailto:support@onsetfinancial.com'} target={'_blank'} rel={'noreferrer'}><b>Contact Support</b></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) || null

    return children
  }
}
