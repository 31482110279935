import React, { FunctionComponent } from 'react'
import captechLogo from '../../assets/images/captech-row-icon.png'
import mazumaLogo from '../../assets/images/mz-row-icon.png'
import onsetLogo from '../../assets/images/ofi-row-icon.png'

interface TenantLogo {
  [name: string]: string
}

const tenantLogo: TenantLogo = {
  captech: captechLogo,
  mazuma: mazumaLogo,
  onset: onsetLogo,
}

interface Props {
  tenant: string
}

const RowLogo: FunctionComponent<Props> = (props) => {
  const { tenant } = props
  const logo = tenantLogo[tenant]

  return (
    <img src={logo} height={'25'} alt={tenant} />
  )
}

export default RowLogo
