import React, { FunctionComponent, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import TopBar from '../common/layout/top-bar/TopBar'
import DealsMain from './deals/Main'
import BottomBar from '../common/layout/BottomBar'
// import SideBar from '../common/layout/SideBar'
// import HomeDash from './home-dash/HomeDash'

// Plugin CSS
import './../assets/libs/flatpickr/flatpickr.min.css'

// Core Theme CSS
import './../assets/css/bootstrap.min.css'
import './../assets/css/icons.min.css'
import './../assets/css/app.min.css'

const Main: FunctionComponent = (props) => {
  const [ sideMenuIsOpen, setSideMenuIsOpen ] = useState(false)
  const { path } = useRouteMatch()

  const toggleSideBarMenu = () => {
    // currently forcing menu always to collapsed unless mobile
    const isSmallScreen = window.innerWidth < 768
    if (isSmallScreen) {
      setSideMenuIsOpen(!sideMenuIsOpen)
    } else {
      setSideMenuIsOpen(false)
    }
  }

  useEffect(() => {
    document.body.classList.remove("p-0")
  }, [])

  useEffect(() => {
    const isSmallScreen = window.innerWidth < 768
    if (isSmallScreen) {
      setSideMenuIsOpen(true)
    }
  }, [])

  // NOTE: Side Menu currently disabled so extra classes can't be applied without screwing with layout of the page
  // useEffect(() => {
  //   // There is a better way to handle this long term, but for now with the class structure of the Theme this will work.
  //   if (sideMenuIsOpen) {
  //     document.body.classList.remove("sidebar-enable")
  //     document.body.classList.remove("enlarged")
  //   } else {
  //     document.body.classList.add("sidebar-enable")
  //     const isSmallScreen = window.innerWidth < 768
  //     if (!isSmallScreen) {
  //       document.body.classList.add("enlarged")
  //     }
  //   }
  // }, [sideMenuIsOpen])


  // Dash is temporarily disabled until we decide on a use.
  // SideBarNav is temporarily disabled until we expand the app.
  // NOTE: ml-0 added to .content-page to overwrite margin for sideBar remove when enabling
  return (
    <>
      <TopBar
        toggleMenu={toggleSideBarMenu}
      />
      {/*<SideBar />*/}
      <div className={'content-page ml-0'}>
        <div className={'content'}>
          <div className={'container-fluid'}>
            <Switch>
              <Route path={[`${path}transactions/:brand/:id`, `${path}transactions/`]}>
                <DealsMain />
              </Route>
              <Route
                exact
                path={'/*'}
                render={() => <Redirect to={{ pathname: '/portal/transactions/'}} />}
              />
              {/*<Route path={`${path}`}>*/}
              {/*  <HomeDash />*/}
              {/*</Route>*/}
            </Switch>
          </div>
        </div>
        <BottomBar />
      </div>
    </>
  )
}

export default Main
