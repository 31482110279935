import React, { FunctionComponent } from 'react'
import { Comment } from '../../models/comment'
import DateUtils from '../../utils/date-utils'

// Unsure how this self will be identified, will eed to be based on the full name or we will need to store an id
interface Props extends Comment {
  selfId: string
}

const CommentItem: FunctionComponent<Props> = (props) => {
  const { body, createdAt, userFullName, userSub, selfId } = props
  const splitData = DateUtils.getDateInfoFromStamp(createdAt)
  let dateData = 'Invalid Date Saved'
  if (splitData) {
    const { ampm, day, hours, minutes, month} = splitData
    dateData = ` ${month}/${day} ${hours}:${minutes} ${ampm}`
  }
  return (
    <li className={`clearfix ${selfId === userSub && 'odd'}`}>
      <div className="conversation-text">
        <div className="ctext-wrap">
          <i>{userFullName} <span>{dateData}</span></i>
          <p>
            {body}
          </p>
        </div>
      </div>
    </li>
  )
}

export default CommentItem
