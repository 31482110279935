// TODO: All of these constants are straight from Edge UI, they should be relooked at in the future.

interface BillingCycleProp {
  [name: number]: {
    name: string
    multiplier: number
  }
}

export const billingCycles: BillingCycleProp = {
  0: {
    name: 'Monthly',
    multiplier: 1,
  },
  1: {
    name: 'Quarterly',
    multiplier: 3,
  },
}

interface CLPBalloonPaymentTypeProps {
  [name: string]: string
}

export const CLPBalloonPaymentTypes: CLPBalloonPaymentTypeProps = {
  NONE: 'None',
  PERCENTAGE: 'Percentage',
  DOLLAR_AMOUNT: 'Dollar Amount',
  BOTH: 'Both',
}


export const CLPStepPaymentTypes = {
  APPROVED: 'APPROVED',
  SENT: 'SENT',
}

export const onsetStatuses = {
  WITHDRAWN: 'Withdrawn',
  RESTRUCTURE: 'Restructure',
  WALKED: 'Walked',
  DECLINED: 'Declined',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  APPROVED_WITH_CHANGES: 'Approved with Changes',
  HOLD: 'Hold',
}

export const onsetStatusBadgeClasses = (status: string) => {
  if ([onsetStatuses.PENDING].includes(status)) {
    return 'badge-primary'
  } else if ([onsetStatuses.WITHDRAWN, onsetStatuses.WALKED, onsetStatuses.DECLINED].includes(status)) {
    return 'badge-danger'
  } else if ([onsetStatuses.HOLD].includes(status)) {
    return 'badge-warning'
  } else if ([onsetStatuses.APPROVED_WITH_CHANGES, onsetStatuses.APPROVED].includes(status)) {
    return 'badge-success'
  } else {
    return 'badge-info'
  }
}
