import { API } from 'aws-amplify'

export function getDeal(id, tenant) {
  const apiName = 'portalapi'
  const path = `/portal-api/deals/${id}`

  return API.get(apiName, path, {
    'queryStringParameters': {
      tenant: tenant,
    }
  })
}

export function getDealListData(filters) {
  const { onsetStatusIn, ...theRest } = filters
  const apiName = 'portalapi'
  const path = '/portal-api/deals'
  return API.get(apiName, path, {
    'queryStringParameters': {
      ...theRest,
      onsetStatusIn: JSON.stringify(onsetStatusIn)
    }
  })
}

export function getDataSigned(location) {
  const apiName = 'portalapi'
  const path = '/portal-api/presigned-url'

  return API.get(apiName, path, {
    'queryStringParameters': {
      'location': location,
    }
  })
}

export function getDealListDataSummary(filters) {
  const { onsetStatusIn, ...theRest } = filters
  const apiName = 'portalapi'
  const path = '/portal-api/aggregate-deals'

  return API.get(apiName, path, {
    'queryStringParameters': {
      ...theRest,
      onsetStatusIn: JSON.stringify(onsetStatusIn)
    }
  })
}

export function getSignedZip(files) {
  const apiName = 'portalapi'
  const path = '/portal-api/presigned-url-zip'

  return API.post(apiName, path, {
    body: {
      files,
    }
  })
}

export function getUnreadDealDocuments(id, tenantName) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${id}/unread-documents`

  return API.get(apiName, path, {
    'queryStringParameters': {
      tenantName
    }
  })
}

export function getUnreadDealDocumentsPresent(id, tenantName) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${id}/unread-documents-present`

  return API.get(apiName, path, {
    'queryStringParameters': {
      tenantName
    }
  })
}


export function getUnreadDeal(clpId, tenantName) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${clpId}/unreads`

  return API.get(apiName, path, {
    'queryStringParameters': {
      tenantName,
    }
  })
}

export function getUnreadDealPresent(clpId, tenantName) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${clpId}/unreads-present`

  return API.get(apiName, path, {
    'queryStringParameters': {
      tenantName,
    }
  })
}

export function markDealAsRead(id, tenant, viewed) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${id}/unreads`

  return API.patch(apiName, path, {
    'body': {
      tenantName: tenant,
      viewed
    }
  })
}

export function markDealDocumentAsRead(id, documentId, tenant, viewed) {
  const apiName = 'portalapi'
  const path = `/portal-api/deal/${id}/unread-documents`

  return API.patch(apiName, path, {
    'body': {
      documentId,
      tenantName: tenant,
      viewed
    }
  })
}
