import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { PagerProps } from '../models/pager'

interface Props {
  count: number,
  pager: PagerProps,
  updatePager: (pager: PagerProps) => void
}

interface PagerNavProps {
  hasFirstPrevious: boolean,
  current: number,
  hasNextLast: boolean,
}

const Pager: FunctionComponent<Props> = (props) => {
  const  { count, pager, updatePager } = props
  const [ nav, setNav ] = useState<PagerNavProps>({
    hasFirstPrevious: false,
    current: 1,
    hasNextLast: false,
  })

  const totalNumberOfPages = Math.ceil(count / pager.limit)

  const calculateNavs = useCallback(() => {
    const currentPageNumber = (pager.limit + pager.offset) / pager.limit
    setNav({
      hasFirstPrevious: !(pager.offset === 0),
      current: currentPageNumber,
      hasNextLast: !(currentPageNumber === totalNumberOfPages),
    })
  }, [pager, setNav, totalNumberOfPages])

  useEffect(() => {
    calculateNavs()
  }, [calculateNavs, count, pager])

  const setPager = (hasNavigation: boolean, offset: number) => {
    if (hasNavigation) {
      updatePager({
        limit: pager.limit,
        offset: offset,
      })
    }
  }

  if (count < pager.limit) {
    return (
      <ul className={'pagination pagination-rounded justify-content-end mb-0 mt-2'} />
    )
  }

  const { current, hasFirstPrevious, hasNextLast} = nav
  const { limit, offset } = pager
  return (
    <ul className={'pagination pagination-rounded justify-content-end mb-0 mt-2'}>
      <li className={`page-item ${hasFirstPrevious ? '' : 'disabled'}`} onClick={() => setPager(hasFirstPrevious, offset - limit)}>
        <span className="page-link cursor-pointer-custom" aria-label="Previous">
          <span aria-hidden="true">«</span>
          <span className="sr-only">Previous</span>
        </span>
      </li>
      <li className="page-item active"><span className="page-link">{current}</span></li>
      <li className={`page-item ${hasNextLast ? '' : 'disabled'}`} onClick={() => setPager(hasNextLast, offset + limit)}>
        <span className="page-link cursor-pointer-custom" aria-label="Next">
          <span aria-hidden="true">»</span>
          <span className="sr-only">Next</span>
        </span>
      </li>
    </ul>
  )
}

export default Pager
