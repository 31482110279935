import { useCallback, useEffect, useState } from 'react'
import { UnreadDealInfoProps } from '../models/deals'
import { getUnreadDeal } from '../services/api'

interface UnreadDealId {
  clpId: number
  tenantName: string
}

export function useLoadUnreadDeal () {
  const [data, setData] = useState<Array<UnreadDealInfoProps>>([])
  const [error, setError] = useState<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reqBody, setReqBody] = useState<UnreadDealId | null>(null)

  const loadDealUnreads = useCallback(async () => {
    setIsSubmitting(true)
    setError(null)
    setData([])

    try {
      if (reqBody?.clpId && reqBody.tenantName) {
        const resp = await getUnreadDeal(reqBody.clpId, reqBody.tenantName)
        if (resp?.body?.data) {
          setData(resp.body.data)
        }
      }
    } catch (e) {
      setError({ message: 'There was an issue loading unread deal data.', errorBody: e })
    } finally {
      setIsSubmitting(false)
    }
  }, [reqBody, setData, setError, setIsSubmitting])

  useEffect(() => {
    if (reqBody) {
      loadDealUnreads().then()
    }
  }, [loadDealUnreads, reqBody])

  return {
    data,
    error,
    isSubmitting,
    setReqBody,
  }
}
