import React, { FunctionComponent, useEffect, useState } from 'react'
import { SentInfoProps } from '../../../common/models/deals'
import numeral from 'numeral'
import DealSingleStepPayments from './StepPayments'

interface Props {
  defaultOpen: boolean
  sentInfo: SentInfoProps
  hasUnread: boolean
  isApproved: boolean
}

const DealSingleSent: FunctionComponent<Props> = (props) => {
  const { defaultOpen, hasUnread, isApproved, sentInfo } = props
  const [ isExpanded, setIsExpanded ] = useState(false)

  useEffect(() => {
    setIsExpanded(defaultOpen)
  }, [defaultOpen, setIsExpanded])

  const { amountSentOut, billingCycle, collateral,
    enhancement, equipment, leaseRateFactorSent, payment, sentStepPayments, term,
  } = sentInfo
  const hasStepPayments = sentStepPayments.length > 0
  return (
    <>
      <h5
        className={'mb-2 mt-3 text-uppercase bg-light p-2'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {
          hasUnread
            ? <span className={'position-relative pl-2'}><span className={'unread-light-icon'} /> Requested Terms <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} /></span>
            : (<>Requested Terms <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} /></>)
        }

      </h5>
      <div className={`collapse pl-3 ${isExpanded ? 'show' : ''}`}>
        <p className={`mb-0 ${!isApproved && 'd-none'}`}><b>Amount Sent:</b> {numeral(amountSentOut).format('$0,0.00')}</p>
        {/*<p className={`mb-0 ${!isApproved && 'd-none'}`}><b>Date Sent:</b> {dateSent}</p>*/}
        <p className={`mb-0 ${hasStepPayments ? 'd-none' : ''}`}>
          <b>Lease Rate Factor:</b> {leaseRateFactorSent ? numeral(leaseRateFactorSent).format('.00000') : 'No Lease Rate Factor Added'}
        </p>
        <p className={`mb-0`}><b>Term:</b> {`${term} months` || 'No Term Listed'}</p>
        <p className={`mb-0`}><b>Equipment:</b> {equipment || 'No Equipment Listed'}</p>
        <p className={`mb-0 ${hasStepPayments ? 'd-none' : ''}`}><b>Payment:</b> {payment ? numeral(payment).format('$0,0.00') : 'No Payment Added'}</p>
        <p className={`mb-0`}><b>Billing Cycle:</b> {billingCycle}</p>
        <p className={`mb-0`}><b>Collateral:</b> {collateral || 'No Collateral Added'}</p>
        <p className={`mb-0`}><b>Enhancement:</b> {enhancement || 'No Enhancement Added'}</p>
        <DealSingleStepPayments stepPayments={sentStepPayments} />
      </div>
    </>
  )
}

export default DealSingleSent
