import { useCallback, useEffect, useState } from 'react'
import { DealBase } from '../models/credit-lender-package'
import { getDealListData, getDealListDataSummary } from '../services/api'
import { DealFilterProps } from '../models/deals'
import FilterUtils from '../utils/filter-utils'
import { PagerProps } from '../models/pager'

export interface SummaryDataProps {
  amountSentOutTotal: number
  approvedAmountTotal: number
  daysPendingAverage: number
}

interface ListFilterCallProps extends DealFilterProps, PagerProps {}

const defaultPager: PagerProps = {limit: 10, offset: 0}
const defaultSummaryData = { amountSentOutTotal: 0, approvedAmountTotal: 0, daysPendingAverage: 0 }
// TODO: Filter still needs to become dependent on the same base variable. Currently working off of two separate
// parts that assume the same default via types and an uncontrolled Filter form.
const defaultFilter = {
  companyNameLike: '',
  dateSentGt: '',
  dateSentLt: '',
  onsetStatusIn: ['Pending'],
}

export function useLoadDeals () {
  const [ data, setData ] = useState<Array<DealBase>>([])
  const [ summaryData, setSummaryData ] = useState<SummaryDataProps>(defaultSummaryData)
  const [ count, setCount ] = useState<number>(0)
  const [ filters, setFilters ] = useState<DealFilterProps>(defaultFilter)
  const [ pager, setPager ] = useState<PagerProps>(defaultPager)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ error, setError ] = useState<any>(null)
  const [ summaryError, setSummaryError ] = useState<any>(null)

  const loadListSummaryData = useCallback(async (combinedFilters: ListFilterCallProps) => {
    try {
      const resp = await getDealListDataSummary(combinedFilters)
      if (resp?.success && resp?.body?.data) {
        const { amountSentOutTotal, approvedAmountTotal, daysPendingAverage } = resp.body.data
        setSummaryData({
          approvedAmountTotal: approvedAmountTotal && parseFloat(approvedAmountTotal),
          amountSentOutTotal: amountSentOutTotal && parseFloat(amountSentOutTotal),
          daysPendingAverage: daysPendingAverage && parseInt(daysPendingAverage),
        })
      } else {
        // TODO: Conversation around how we want to handle errors globally/internally/per component
        setSummaryError('There has been an error')
      }
    } catch (e) {
      setSummaryError(e)
    }
  }, [setSummaryData, setSummaryError])

  const loadListData = useCallback(async (combinedFilters: ListFilterCallProps) => {
    setCount(0)
    setData([])
    try {
      const resp = await getDealListData(combinedFilters)
      if (resp?.body?.data) {
        setData(resp.body.data)
        setCount(resp.body.count)
      } else {
        setError('There has been an error with the retrieved List Data')
      }
    } catch (e) {
      setError(e)
    }
  }, [setCount, setData, setError])

  const loadDeals = useCallback(async () => {
    setIsSubmitting(true)
    setError(null)

    const filteredFilters = FilterUtils.filterRemoveEmpties(filters)

    const combinedFilters = {
      ...filteredFilters,
      ...pager,
    }

    try {
      await Promise.all([
        loadListData(combinedFilters),
        loadListSummaryData(combinedFilters),
      ])
    } catch (e) {
      console.log(e)
      // NOTE: Currently using the default data as general error, should be updated once the discussion around Error handling happens.
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }, [ filters, loadListData, loadListSummaryData, pager, setError, setIsSubmitting])

  const clearError = () => {
    setError(null)
    setSummaryError(null)
  }

  const updatePager = (newPager: PagerProps) => {
    setPager(newPager)
  }

  const updateFilters = (filters: DealFilterProps) => {
    setPager(defaultPager)
    setFilters(filters)
  }

  useEffect(() => {
    loadDeals().then()
  }, [filters, loadDeals, pager])

  return {
    clearError,
    count,
    data,
    error,
    filters,
    isSubmitting,
    pager,
    summaryData,
    summaryError,
    updatePager,
    updateFilters,
  }
}
