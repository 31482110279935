import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'

const BottomBar: FunctionComponent = () => {
  return (
    <footer className={'footer'}>
      <Container fluid>
        <Row>
          <Col md={6} className={'footer-links'}>
            Copyright &copy; 2020 <a href={'http://onsetfinancial.com'} target={'_blank'} rel={'noreferrer'}>Onset Financial, Inc</a>.
          </Col>
          <Col md={6}>
            <div className={'text-md-right footer-links d-none d-sm-block'}>
              <a href={'mailto:support@onsetfinancial.com'} target={'_blank'} rel={'noreferrer'}>Contact Support</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default BottomBar
