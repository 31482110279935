import { API } from 'aws-amplify'
import { ClpCommentPostBody, ClpCommentReq } from '../models/comment'

const apiName = 'cipClpComment'

export function getComments(req: ClpCommentReq) {
  const path = '/comments'
  return API.get(apiName, path, {
    queryStringParameters: {
      ...req
    }
  })
}

export function sendComment(req: ClpCommentPostBody) {
  const path = '/comments'
  return API.post(apiName, path, {
    body: {
      ...req
    }
  })
}
