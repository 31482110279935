import { useState } from 'react'
import { sendComment } from '../services/cip-clp-api'
import { ClpCommentPostBody } from '../models/comment'
import { Callback } from '../models/callback'

interface SubmitError {
  message: string
  originalBody: string
}

export function useSubmitComment () {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<SubmitError | null>(null)

  const submitComment = async (req: ClpCommentPostBody, reloadFunc: Callback): Promise<void> => {
    setIsSubmitting(true)

    try {
      await sendComment(req)
      reloadFunc()
    } catch (e) {
      setError({
        message: 'There has been an issue saving your comment.',
        originalBody: req.body
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const clearError = () => {
    setError(null)
  }

  return {
    clearError,
    error,
    isSubmitting,
    submitComment,
  }
}
