import { useCallback, useEffect, useState } from 'react'
import { getDeal } from '../services/api'
import { DealSingleProps } from '../models/credit-lender-package'
import DateUtils from '../utils/date-utils'
import StepPaymentUtils from '../utils/step-payment-utils'

interface DealRequestBody {
  clpId: number | null,
  tenantName: string | null,
}

export function useLoadDeal () {
  const [ data, setData ] = useState<DealSingleProps | null>(null)
  const [ error, setError ] = useState<any>(null)
  const [ isSubmitting, setIsSubmitting ] = useState(false)
  const [ reqBody, setReqBody ] = useState<DealRequestBody>({clpId: null, tenantName: null})

  const updateReqBody = useCallback((clpId: number, tenantName: string) => {
    if (clpId && tenantName) {
      setReqBody({
        clpId,
        tenantName,
      })
    }
  }, [setReqBody])

  const loadDeal = useCallback(async (clpId: number, tenantName: string) => {
    setData(null)
    setIsSubmitting(true)
    setError(null)

    try {
      const response = await getDeal(clpId, tenantName)
      if (response?.body?.data) {
        const {
          createdAt, dateSent, expiration, responseDate, stepPayments, updatedAt,
          ...data
        } = response.body.data
        const formattedData = {
          createdAt: DateUtils.formatDateFromStamp(createdAt),
          dateSent: DateUtils.formatDateFromStamp(dateSent),
          expiration: DateUtils.formatDateFromStamp(expiration),
          responseDate: DateUtils.formatDateFromStamp(responseDate),
          updatedAt: DateUtils.formatDateFromStamp(updatedAt),
          ...data,
          ...StepPaymentUtils.separateStepPayments(stepPayments)
        }
        setData(formattedData)
      } else {
        setError('Issue with retrieved Data')
      }
    } catch (e) {
      console.log(e)
      setError(e)
    } finally {
      setIsSubmitting(false)
    }
  }, [setData, setError, setIsSubmitting])

  useEffect(() => {
    const { clpId, tenantName } = reqBody
    if (clpId && tenantName) {
      loadDeal(clpId, tenantName).then()
    }
  }, [loadDeal, reqBody])

  return {
    data,
    error,
    isSubmitting,
    loadDeal,
    updateReqBody,
  }
}
