import React, { Fragment, FunctionComponent } from 'react'
import { Auth } from '@aws-amplify/auth'
import keystoneLogo from '../../../../assets/images/keystone-logo.png'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useHistory } from 'react-router-dom'

const LeftTopBarProfile: FunctionComponent = () => {
  // Note: break layout shown with placeholder option for future expansion
  const { replace } = useHistory()


  const signOut = async () => {
    // Based on AmplifySignOut, it does not have a properly setup slot="name" as docs indicate. Functionality works...
    // ...as desired, but more testing and error handling is needed.
    if (!Auth || typeof Auth.signOut !== 'function') {
      throw new Error('Error with Auth Module');
    }
    await Auth.signOut()
    replace('/portal/')
  }

  return (
    <Fragment>
      <UncontrolledDropdown>
        <DropdownToggle
          tag={'button'}
          className={'btn btn-link nav-link dropdown-toggle waves-effect waves-light mr-0'}
        >
          <img src={keystoneLogo} alt="user-img" width={40} />
          <span className="pro-user-name ml-1">
            Keystone <i className="mdi mdi-chevron-down" />
        </span>
        </DropdownToggle>
        <DropdownMenu right className={'dropdown-menu dropdown-menu-right profile-dropdown'}>
          {/*<div className="dropdown-header noti-title">*/}
          {/*  <h6 className="text-overflow m-0">Welcome !</h6>*/}
          {/*</div>*/}
          {/*<Link to={'/'} className={'dropdown-item notify-item'}>*/}
          {/*  <i className="fe-lock" />*/}
          {/*  <span>Lock Screen</span>*/}
          {/*</Link>*/}
          {/*<div className="dropdown-divider" />*/}
          <div slot={'sign-out'} className={'dropdown-item notify-item cursor-pointer-custom'} onClick={signOut}>
            <i className="fe-log-out" />
            <span>Logout</span>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  )
}

export default LeftTopBarProfile
