import React, { FunctionComponent } from 'react'
import { StepPayment } from '../../../common/models/step-payment'
import { Col, Row } from 'reactstrap'
import numeral from 'numeral'

interface Props {
  stepPayments: Array<StepPayment>
}

const DealSingleStepPayments: FunctionComponent<Props> = (props) => {
  const { stepPayments } = props

  if (stepPayments.length === 0) {
    return null
  }
  const generateRows = () => {
    let startMonth = 1
    return stepPayments.map(step => {
      const endMonth = step.months
      const row = (
        <Row key={step.id}>
          <Col sm={4}>
            <p className={`mb-0`}><b>Months:</b> {startMonth} - {endMonth + startMonth - 1}</p>
          </Col>
          <Col sm={8}>
            <p className={`mb-0`}><b>Lease Rate Factor:</b> {numeral(step.leaseRateFactor).format('.00000')}</p>
          </Col>
        </Row>
      )
      startMonth = startMonth + endMonth
      return row
    })
  }
  return (
    <>
      <h5>Step Payments</h5>
      {generateRows()}
    </>
  )
}

export default DealSingleStepPayments
