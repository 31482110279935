import React, { FunctionComponent, ReactNode } from 'react'
import { Alert } from 'reactstrap'

interface Props {
  color: string
  message: string | ReactNode
  onClose: () => void
}

const AlertMessage: FunctionComponent<Props> = (props) => {
  // NOTE: Handling with show/hide being managed by parent component due to dev only warning on Fade from
  // reactstrap not updating their transition dependency yet. https://github.com/reactstrap/reactstrap/issues/1833#issue-614566421
  const { color, message, onClose } = props
  return (
    <Alert
      color={color}
      toggle={onClose}
    >
      {message}
    </Alert>
  )
}

export default AlertMessage
