import React, { FunctionComponent } from 'react'

const GlobalSearch: FunctionComponent = () => {
  // NOTE: Unused component for future expansion.
  return (
    <form className="app-search">
      <div className="app-search-box">
        <div className="input-group">
          <input type="text" className="form-control" placeholder="Search..." />
          <div className="input-group-append">
            <button className="btn" type="submit">
              <i className="fe-search" />
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default GlobalSearch
