import { useCallback, useEffect, useState } from 'react'
import { SelectedDoc, UnreadDocumentBase } from '../models/document'
import { getUnreadDealDocuments } from '../services/api'

interface LoadDealUnreadDocumentsBody {
  clpId: number | null
  tenant: string | null
}

export function useLoadDealUnreadDocuments () {
  const [data, setData] = useState<Array<UnreadDocumentBase> | []>([])
  const [error, setError] = useState<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reqBody, setReqBody] = useState<LoadDealUnreadDocumentsBody | null>(null)

  const loadUnreadDealDocuments = useCallback(async () => {
    if (reqBody) {
      setError(null)
      setIsSubmitting(true)
      setData([])
      const { clpId, tenant } = reqBody

      try {
        const resp = await getUnreadDealDocuments(clpId, tenant)

        if (resp?.body?.data) {
          setData(resp.body.data)
        }
      } catch (e) {
        setError({ message: 'There has been an issue loading unread Document Information', errorBody: e })
      } finally {
        setIsSubmitting(false)
      }
    } else {
      setError({ message: 'There has not been a tenant and clpId set.' })
    }
  }, [reqBody, setData, setError, setIsSubmitting])

  const updateReqBody = useCallback((clpId: number, tenant: string) => {
    if (clpId && tenant) {
      setReqBody({
        clpId,
        tenant,
      })
    }
  }, [setReqBody])

  const markAsRead = useCallback((readDocs: Array<SelectedDoc>) => {
    // Discuss if this is adequate or if we want to just make the call again anyways to refresh removed ones.
    const remainingUnreadsArr = data.filter((doc: UnreadDocumentBase) => {
      return readDocs.findIndex(readDoc => readDoc.id === doc.documentId) === -1
    })
    setData(remainingUnreadsArr)
  }, [data, setData])

  useEffect(() => {
    if (reqBody?.clpId && reqBody.tenant) {
      loadUnreadDealDocuments().then()
    }
  }, [loadUnreadDealDocuments, reqBody])

  return {
    data,
    error,
    isSubmitting,
    markAsRead,
    reqBody,
    updateReqBody,
  }
}
