import React, { FunctionComponent, useCallback, useState } from 'react'
import { getDataSigned, markDealDocumentAsRead } from '../../../common/services/api'
import { Badge } from 'reactstrap'
import { Tenant } from '../../../common/models/tenant'
import { SelectedDoc } from '../../../common/models/document'

interface Props {
  clpId: number
  dealTenant: Tenant
  documentId: number
  isUnread: boolean
  markAsDocumentRead: (ids: Array<SelectedDoc>) => void
  userCompany: string | null
  s3Path: string
}

const DealSingleDocumentLink: FunctionComponent<Props> = (props) => {
  const { clpId, dealTenant, documentId, isUnread, markAsDocumentRead, s3Path, userCompany } = props
  const [ loadingDoc, setLoadingDoc ] = useState(false)

  const patchAsRead = useCallback(async () => {
    // TODO: Swap this to !== before prod push
    if (userCompany !== 'onset' && isUnread) {
      try {
        const viewed = new Date().toISOString()
        await markDealDocumentAsRead(clpId, documentId, dealTenant.tenantName, viewed)
        markAsDocumentRead([{ id: documentId, s3Path }])
      } catch (e) {
        // TODO: Same as group call, what do when unreads fail to be marked as read from server.
        console.log(e)
      }
    }
  }, [clpId, documentId, dealTenant, isUnread, markAsDocumentRead, s3Path, userCompany])

  const fetchData = useCallback(async () => {
    setLoadingDoc(true)
    let formattedPath = s3Path
    if (formattedPath[0] === '/') {
      formattedPath = formattedPath.slice(1)
    }
    const resp = await getDataSigned(formattedPath)
    if (resp.body) {
      window.open(resp.body, '_blank')
      await patchAsRead()
    }
    setLoadingDoc(false)
  }, [patchAsRead, s3Path])

  return !loadingDoc
    ? <Badge className={'ml-2 cursor-pointer-custom'} color={'info'} onClick={fetchData}>Download</Badge>
    : (
      <div className="spinner-border m-2" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
}

export default DealSingleDocumentLink
