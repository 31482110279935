import React, { FunctionComponent } from 'react'

const LeftTopBarSettings: FunctionComponent = () => {
  // NOTE: Unused component for future expansion.
  return (
    <span className="nav-link right-bar-toggle waves-effect waves-light">
      <i className="fe-settings noti-icon" />
    </span>
  )
}

export default LeftTopBarSettings
