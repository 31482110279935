import { DealFilterProps } from '../models/deals'

const FilterUtils = {
  filterRemoveEmpties: function (filters: DealFilterProps) {
    const formData:any = {}
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'companyNameLike') {
        // Any value with false as a valid entry will need an entry here
        formData[key] = value
        return false
      }
      if (!value) {
        return false
      }
      if (key === 'dateSentLt' || key === 'dateSentGt') {
        formData[key] = new Date(value).toISOString()
        return false
      }
      if (Array.isArray(value)) {
        formData[key] = value
        return false
      }
      // if (typeof value === 'object') {
      //   if (value.value || value.value === false) {
      //     formData[key] = value.value
      //   }
      //   // handle other types of objects if they ever are needed, ex. an object that has false as a valid property
      //   return false
      // }
      formData[key] = value
    })
    return formData
  }
}

export default FilterUtils
