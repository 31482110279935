import React, { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import AlertMessage from '../AlertMessage'

interface Props {
  canCreateComment: boolean
  clearSubmitError: () => void
  commentSubmit: (body:string) => void
  refreshComments: () => void
  submitIsSubmitting: boolean
  submitError: {
    message: string
    originalBody: string
  } | null
}

const CommentForm: FunctionComponent<Props> = (props) => {
  const { canCreateComment, clearSubmitError, commentSubmit, refreshComments, submitIsSubmitting, submitError } = props

  const formMethods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      body: ''
    }
  })

  const { register, formState, handleSubmit, reset } = formMethods
  const { isSubmitting, isDirty } = formState

  const onSubmit = (values: {body: string}) => {
    commentSubmit(values.body)
    reset()
  }

  let errorMessage = null
  if (submitError) {
    errorMessage = (
      <AlertMessage
        color={'danger'}
        message={(
          <span>
            {submitError.message}
            <br/>
            Original Comment: {submitError.originalBody}
          </span>
        )}
        onClose={clearSubmitError}
      />
    )
  }

  return (
    <>
      {errorMessage}
      {!canCreateComment && (<AlertMessage color={'warning'} message={'You will need to logout and back in to send comments.'} onClose={() => true} />)}
      <form className={'w-100'}>
        <Row>
          <Col className={'pl-2'}>
            <input
              type={'text'}
              disabled={!canCreateComment}
              className={'form-control chat-input'}
              name={'body'}
              placeholder={'Leave a Comment'}
              ref={register({required: true})}
            />
          </Col>
          <Col className={'col-auto'}>
            <div className={'d-flex justify-content-end align-items-center'}>
              <button
                type="button"
                className="btn btn-success mr-1 waves-effect waves-light button-large-mdi-icon"
                onClick={refreshComments}
              >
                <i className={'mdi mdi-refresh'} />
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !isDirty || submitIsSubmitting || !canCreateComment}
                className="btn btn-danger waves-effect waves-light"
                onClick={handleSubmit(onSubmit)}
              >
                Send
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  )
}

export default CommentForm
