import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useLoadDeal } from '../../common/hooks/useLoadDeal'
import DealSingleContainer from './single-components/Container'
import { markDealAsRead } from '../../common/services/api'
import { useLoadUnreadDeal } from '../../common/hooks/useLoadUnreadDeal'
import { UserContext } from '../../common/contexts/user'

const DealsSingle: FunctionComponent = () => {
  const rm = useRouteMatch<any>()
  const currentId = parseInt(rm?.params?.id) || null
  const tenant = rm?.params?.brand || null
  const { data, isSubmitting, updateReqBody } = useLoadDeal()
  const userContext = useContext(UserContext)

  // TODO: Discuss, I think this can exist as an exposed context wrapping these components since they are being passed
  // multiple levels without real necessity.
  const { data: unreadDealData, setReqBody: unreadDealUpdateReqBody} = useLoadUnreadDeal()

  let content = (
    <div className={'media mb-0'}>
      <div className={'media-body'}>
        <h4 className={'mt-0 mb-1'}>Select a Transaction from the List...</h4>
      </div>
    </div>
  )

  if (currentId && tenant) {
    if ( isSubmitting ) {
      content = (
        <div className={'d-flex justify-content-center'}>
          <div className="spinner-border avatar-lg text-primary m-2" role="status" />
        </div>
      )
    } else {
      content = (
        <DealSingleContainer data={data} unreadDealData={unreadDealData} />
      )
    }
  }

  const patchMarkDealAsRead = useCallback( () => {
    if (userContext?.attributes['custom:companyName'] !== 'onset') {
      try {
        const viewed = new Date().toISOString()
        // NOTE: We could do something after the deal is marked as viewed, but currently better to keep it as shown.
        setTimeout(() => {
            markDealAsRead(currentId, tenant, viewed).then()
          }, 5000
        )
      } catch (e) {
        console.log(e)
      }
    }
  }, [currentId, tenant, userContext])

  const loadUnreads = useCallback( () => {
    if (currentId && tenant) {
      unreadDealUpdateReqBody({ clpId: currentId, tenantName: tenant })
    }
  }, [currentId, tenant, unreadDealUpdateReqBody])

  useEffect(() => {
    // NOTE: Runs 4 times on React default double reload, but runs once when navigated to or reloaded on.
    // Another way to handle how to mark unreads should be found.
    if (data && unreadDealData.length > 0) {
      patchMarkDealAsRead()
    }
  }, [data, patchMarkDealAsRead, unreadDealData])

  useEffect(() => {
    if (currentId && tenant) {
      updateReqBody(currentId, tenant)
      loadUnreads()
      // TODO: We can set this up to only run on success of the initial, but we can discuss because there are benefits to just having it loaded.
    }
  }, [currentId, loadUnreads, tenant, updateReqBody])

  return (
    <div className={'card-box'} id={'detail-view'}>
      {content}
    </div>
  )
}

export default DealsSingle
