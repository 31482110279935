import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import CommentContainer from '../../../common/components/comment/Container'
import { useLoadClpComments } from '../../../common/hooks/useLoadClpComments'
import { useSubmitComment } from '../../../common/hooks/useSubmitComment'
import { ClpCommentPostBody } from '../../../common/models/comment'
import { UserContext } from '../../../common/contexts/user'

// NOTE: This is currently built to only work for CLP with Tenant. In the future that can be handled outside this
// container once we have settled on globally relating things with comments.
const DealSingleComments: FunctionComponent = (props) => {
  // TODO: Do the query for the real comment Data using isOpen to trigger and clpId plus tenantId to fetch the comments
  const [isExpanded, setIsExpanded] = useState(false)
  const { count, data, error, isLoading, loadComments } = useLoadClpComments()
  const { clearError: clearSubmitError, error: submitError, isSubmitting, submitComment } = useSubmitComment()
  const userContext = useContext(UserContext)
  const rm = useRouteMatch<any>()

  const [clpId, setClpId] = useState<number | undefined>(undefined)
  const [tenantName, setTenantName] = useState<string | undefined>(undefined)

  useEffect(() => {
    setClpId(rm?.params?.id)
    setTenantName(rm?.params?.brand)
  }, [rm])

  const onSubmitComment = (body: string) => {
    if (clpId && tenantName) {
      const formData: ClpCommentPostBody = {
        body: body,
        clpId: clpId,
        tenant: tenantName,
        userFullName: userContext.attributes['custom:fullName'],
        userSub: userContext.attributes.sub,
      }
      submitComment(formData, () => loadComments({ clpId: clpId, tenant: tenantName })).then()
    } else {
      // Placeholder escape out, this shouldn't be possible, but needs to be caught until we can guarantee other values.
      window.alert(`There has been an issue submitting your comment. ${body} Please refresh and try again.`)
    }
  }

  const triggerLoadComments = useCallback(() => {
    if (clpId && tenantName) {
      loadComments({
        clpId: clpId,
        tenant: tenantName,
      }).then()
    }
  }, [clpId, loadComments, tenantName])

  useEffect(() => {
    if (clpId && tenantName) {
      triggerLoadComments()
    }
  }, [clpId, loadComments, tenantName, triggerLoadComments])

  useEffect(() => {
    return () => {
      setClpId(undefined)
      setTenantName(undefined)
    }
  }, [])

  let commentsLabel = `${count} Comments`
  if (count === 0) {
    commentsLabel = 'No Comments'
  }

  // Temporary until all users have logged in
  const canCreateComment = (userContext.attributes['custom:fullName'] && userContext.attributes.sub)

  return (
    <>
      <h5
        className={'mb-3 mt-4 text-uppercase bg-light p-2'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {commentsLabel} <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} />
      </h5>
      <div className={`collapse pl-3 ${isExpanded ? 'show' : ''}`}>
        <CommentContainer
          canCreateComment={canCreateComment}
          clearSubmitError={clearSubmitError}
          comments={data}
          commentSubmit={onSubmitComment}
          error={error}
          isLoading={isLoading}
          submitIsSubmitting={isSubmitting}
          refreshComments={triggerLoadComments}
          submitError={submitError}
          selfSub={userContext?.attributes?.sub || ''}
        />
      </div>
    </>
  )
}

export default DealSingleComments
