import React, { Fragment, FunctionComponent } from 'react'

const LeftTopBarNotifications: FunctionComponent = () => {
  // TODO: Flesh this out when we are going to do notifications, reference topbar.html in the style-guide/Light project.
  // NOTE: Unused component for future expansion.
  return (
    <Fragment>
      <span className="nav-link dropdown-toggle  waves-effect waves-light" data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
        <i className="fe-bell noti-icon" />
        <span className="badge badge-danger rounded-circle noti-icon-badge">9</span>
      </span>
      <div className="dropdown-menu dropdown-menu-right dropdown-lg">
        {/* *** Content of notifications goes here, split into other components *** */}
      </div>
    </Fragment>
  )
}

export default LeftTopBarNotifications
