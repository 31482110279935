import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApprovedInfoProps } from '../../../common/models/deals'
import numeral from 'numeral'
import DateUtils from '../../../common/utils/date-utils'
import DealSingleStepPayments from './StepPayments'

interface Props {
  defaultOpen: boolean
  approvedInfo: ApprovedInfoProps
  hasUnread: boolean
}

const DealSingleApproved: FunctionComponent<Props> = (props) => {
  const { approvedInfo, defaultOpen, hasUnread } = props
  const [ isExpanded, setIsExpanded ] = useState(false)

  useEffect(() => {
    setIsExpanded(defaultOpen)
  }, [defaultOpen, setIsExpanded])

  const { appConditions, approvedBillingCycle, approvedCollateral, approvedEnhancement, approvedEquipment,
    approvedLeaseRateFactor, approvedPayment, approvedRate, approvedTerm, approvedStepPayments,
    balloonPercentage, balloonAmount, expiration, responseDate,
  } = approvedInfo

  const hasStepPayments = approvedStepPayments.length > 0

  return (
    <>
      <h5
        className={'mb-3 mt-4 text-uppercase bg-light p-2'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {
          hasUnread
            ? <span className={'position-relative pl-2'}><span className={'unread-light-icon'} /> Approved Terms <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} /></span>
            : <>Approved Terms <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} /></>
        }
      </h5>
      <div className={`collapse pl-3 ${isExpanded ? 'show' : ''}`}>
        <p className="mb-0"><b>Response Date: </b>{responseDate}</p>
        <p className={`mb-0 ${hasStepPayments ? 'd-none' : ''}`}><b>Payment: </b>{approvedPayment ? numeral(approvedPayment).format('$0,0.00') : 'No Payment Added'}</p>
        <p className="mb-0"><b>Billing Cycle: </b>{approvedBillingCycle}</p>
        <p className={`mb-0 ${hasStepPayments ? 'd-none' : ''}`}>
          <b>Lease Rate Factor:</b> {approvedLeaseRateFactor ? numeral(approvedLeaseRateFactor).format('.00000') : 'No Lease Rate Factor Added'}
        </p>
        <p className="mb-0"><b>Rate:</b> {approvedRate ? numeral(approvedRate).format('0.00') : 'No Rate Added'}</p>
        <p className="mb-0"><b>Term:</b> {approvedTerm} months</p>
        <p className="mb-0"><b>Expiration: </b>{DateUtils.formatDateFromStamp(expiration)}</p>
        <p className="mb-0"><b>Enhancement: </b>{approvedEnhancement || 'No Enhancements'}</p>
        <p className="mb-0"><b>Collateral: </b>{approvedCollateral || 'No Collateral'}</p>
        <p className="mb-0"><b>App Conditions: </b>{appConditions || 'No Conditions Listed'}</p>
        <p className="mb-0"><b>Equipment: </b>{approvedEquipment || 'No Equipment Listed'}</p>
        <p className={`mb-0 ${!balloonAmount && 'd-none'}`}><b>Balloon Amount: </b>{balloonAmount}</p>
        <p className={`mb-0 ${!balloonPercentage && 'd-none'}`}><b>Balloon Percentage: </b>{numeral(balloonPercentage).format('0.0')}</p>
        <DealSingleStepPayments stepPayments={approvedStepPayments} />
      </div>
    </>
  )
}

export default DealSingleApproved
