import React, { FunctionComponent } from 'react'
import { DealBase } from '../../../common/models/credit-lender-package'
import DateUtils from '../../../common/utils/date-utils'
import numeral from 'numeral'
import RowLogo from '../../../common/components/RowLogo'

interface Props {
  currentId: number | null,
  hasUnread: boolean
  navigationClick: (id: number, brand: string) => void,
  simpleDeal: DealBase,
  selectedTenantName: string | null,
}

// Note: Potential future functionality, clicking the comment icon will auto expand the comments section on the left.
const DealsListItem: FunctionComponent<Props> = (props) => {
  const { currentId, hasUnread, navigationClick, selectedTenantName, simpleDeal: { amountSentOut, approvedAmount, daysPending, tenant, company, id, dateSent, onsetStatus } } = props

  const isSelected = (currentId === id && selectedTenantName === tenant.tenantName)

  return (
    <>
      <tr className={`cursor-pointer-custom ${isSelected ? 'table-active' : ''}`} onClick={() => navigationClick(id, tenant.tenantName)}>
        {
          hasUnread
          ? <td className={'position-relative pl-3'}><span className={'unread-light-icon ml-1'} />{company.name}</td>
          : <td>{company.name}</td>
        }
        <td>{DateUtils.formatDateFromStamp(dateSent)}</td>
        <td>{daysPending} days</td>
        <td>{numeral(amountSentOut).format('$0,0')}</td>
        <td>{onsetStatus}</td>
        <td>{numeral(approvedAmount).format('$0,0')}</td>
        <td><RowLogo tenant={tenant.tenantName} /></td>
        <td className={'action-icons-cell pb-0 pt-0'}><i className={'mdi mdi-comment-text-multiple-outline'} /></td>
      </tr>
    </>
  )
}

export default DealsListItem
