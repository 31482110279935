import React, { FunctionComponent } from 'react'
import { Col, Row } from 'reactstrap'
import SingleValueBox from '../../../common/components/SingleValueBox'
import { SummaryDataProps } from '../../../common/hooks/useLoadDeals'
import numeral from 'numeral'

interface Props {
  count: number
  summaryData: SummaryDataProps
}

const DealListSummaryHeader: FunctionComponent<Props> = (props) => {
  const { count, summaryData: { amountSentOutTotal, daysPendingAverage } } = props
  return (
    <>
      <Row>
        <Col md={6}>
          <SingleValueBox
            bgcColor={'bg-blue'}
            icon={'fe-dollar-sign'}
            value={(
              <span>
                {count} Pending
                <br/>
                {numeral(amountSentOutTotal).format('$0,0')}
              </span>
            )}
            valueClass={'box-value-one-four'}
          />
        </Col>
        <Col md={6}>
          <SingleValueBox
            bgcColor={'bg-blue'}
            icon={'fe-layers'}
            title={'Average Due Diligence'}
            value={`${daysPendingAverage || 0} days`}
            valueClass={''}
          />
        </Col>
      </Row>
    </>
  )
}

export default DealListSummaryHeader
