import { useCallback, useState } from 'react'
import { ClpCommentReq, Comment } from '../models/comment'
import { getComments } from '../services/cip-clp-api'


export function useLoadClpComments () {
  const [count, setCount] = useState<number>(0)
  const [data, setData] = useState<Array<Comment>>([])
  const [error, setError] = useState<any | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadComments = useCallback(async (req: ClpCommentReq) => {
    setCount(0)
    setData([])
    setError(null)
    setIsLoading(true)

    try {
      const resp = await getComments(req)
      if (resp?.data) {
        setCount(resp.count)
        setData(resp.data)
      } else {
        // Just another note to have our discussion involving how we want to handle errors.
        setError('There has been an error loading Comments')
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [setCount, setData, setError, setIsLoading])


  return {
    count,
    data,
    error,
    isLoading,
    loadComments,
  }
}
