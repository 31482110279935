import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import longLogo from '../../../../assets/images/onset-financial-logo-dark-bg.svg'
import shortLogo from '../../../../assets/images/onset-logo-sm.png'

const TopBarSideBarLogo: FunctionComponent = () => {
  // NOTE: when sidebar enabled remove d-block from div that has .logo-box
  return (
    <div className={'logo-box d-block'}>
      <Link className={'logo text-center'} to={'/portal/'} style={{color: 'inherit'}}>
        <span className="logo-lg">
          <img src={longLogo} alt="" height="40" />
        </span>
        <span className="logo-sm">
          <img src={shortLogo} alt="" height="40" />
        </span>
      </Link>
    </div>
  )
}

export default TopBarSideBarLogo
