import React, { FunctionComponent, ReactElement } from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
  tableHeader: ReactElement
  tableRows: Array<ReactElement>
  btnToolbar?: ReactElement
  tableStriped?: boolean
}

const ResponsiveTable: FunctionComponent<Props> = (props) => {
  const { btnToolbar, tableHeader, tableRows, tableStriped } = props

  return (
    <Row noGutters>
      <Col sm={12}>
        <div className={'card-box p-2'}>
            <div className={'table-wrapper'}>
              {
                btnToolbar
                  ? (
                    <div className={'btn-toolbar'}>
                      {btnToolbar}
                    </div>
                  )
                  : null
              }
                <div className={'table-responsive'}>
                  <table className={`table${tableStriped ? ' table-striped' : ''}`}>
                    <thead>
                      {tableHeader}
                    </thead>
                    <tbody>
                      {tableRows}
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
      </Col>
    </Row>
  )
}

export default ResponsiveTable
