import React, { FunctionComponent } from 'react'
import NavActionDropDown from '../navigation/NavActionDropDown'
import NavMegaMenu from '../navigation/NavMegaMenu'

const RightTopBar: FunctionComponent<{
  toggleMenu: () => void
}> = (props) => {
  // NOTE: Mega Menu and Action Dropdown are added with their sub functionality pieces for future expansion.
  const { toggleMenu } = props
  const isSmallScreen = window.innerWidth < 768

  // currently disabling menu on all screens not just small
  return (
    <ul className={`list-unstyled topnav-menu topnav-menu-left m-0 d-none ${!isSmallScreen && 'd-none'}`}>
      <li>
        <button className="button-menu-mobile waves-effect waves-light" onClick={toggleMenu}>
          <i className="fe-menu" />
        </button>
      </li>

      {/*<li className={'dropdown d-none d-lg-block'}>*/}
      <li className={'d-none'}>
        <NavActionDropDown />
      </li>

      <li className={'d-none'}>
        <NavMegaMenu />
      </li>
    </ul>
  )
}

export default RightTopBar
