import React, { FunctionComponent } from 'react'

interface Props {
  reasons: Array<string> | []
}

const DealSingleReasons: FunctionComponent<Props> = (props) => {
  const { reasons } = props

  // TODO: Make a complete model with create's so these optional values like nonApprovedReasons can be optional

  let joinedReasons = 'None Listed'
  if (reasons && reasons.length > 0) {
    joinedReasons = reasons.join(', ')
  }

  return (
    <>
      <p className="mb-0"><b>Reasons: </b>{joinedReasons}</p>
    </>
  )
}

export default DealSingleReasons
