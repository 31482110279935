import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { DocumentBase, SelectedDoc } from '../../../common/models/document'
import DealSingleDocumentLink from './DocumentLink'
import ResponsiveTable from '../../../common/components/ResponsiveTable'
import DateUtils from '../../../common/utils/date-utils'
import { useForm } from 'react-hook-form'
import { Input, Button, UncontrolledTooltip } from 'reactstrap'
import { getSignedZip, markDealDocumentAsRead } from '../../../common/services/api'
import { Tenant } from '../../../common/models/tenant'
import { useLoadDealUnreadDocuments } from '../../../common/hooks/useLoadDealUnreadDocuments'
import { UserContext } from '../../../common/contexts/user'

interface Props {
  clpId: number
  documents: Array<DocumentBase>
  dealTenant: Tenant
}

interface DefaultValues {
  documentType: number
}

const DealSingleDocuments: FunctionComponent<Props> = (props) => {
  const { clpId,  dealTenant, documents } = props
  const [isExpanded, setIsExpanded] = useState(true)
  const [filteredDocuments, setFilteredDocuments] = useState<Array<DocumentBase>>(documents)
  const [selectedDocs, setSelectedDocs] = useState<Array<SelectedDoc>>([])
  const [loadingZip, setLoadingZip] = useState(false)
  const [zipError, setZipError] = useState(false)

  const userContext = useContext(UserContext)

  const { data: unreadDocuments, markAsRead: markAsDocumentRead, updateReqBody: unreadDocUpdateReqBody } = useLoadDealUnreadDocuments()

  const selectedEmpty = selectedDocs.length === 0

  const DocumentTypes = useMemo(() => ({
    All: 0,
    Proposal: 1,
    Financials: 2,
    Equipment: 3,
    Other: 4,
  }), [])

  const options = Object.entries(DocumentTypes).map(([key, value]) => (
    <option value={value} key={key}>{key}</option>
  ))

  const defaultValues: DefaultValues = useMemo(() => ({
    documentType: DocumentTypes.All
  }), [DocumentTypes])

  const { register, reset, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues,
  })

  useEffect(() => {
    if (clpId && dealTenant && documents.length > 0) {
      unreadDocUpdateReqBody(clpId, dealTenant.tenantName)
    }
  }, [clpId, dealTenant, documents, unreadDocUpdateReqBody])

  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [defaultValues, reset])

  if (documents.length === 0) {
    return (
      <h5 className={'mb-2 mt-3 text-uppercase bg-light p-2'}>No Documents</h5>
    )
  }

  const handleCheck = (checked: boolean, selected: Array<SelectedDoc>) => {
    const newSelected = [...selectedDocs]

    if (checked) {
      selected.forEach((doc) => {
        newSelected.push(doc)
      })

      setSelectedDocs(newSelected)
      return
    }

    setSelectedDocs(newSelected.filter((doc) => !selected.find((selectedDoc) => selectedDoc.id === doc.id)))
  }

  const handleMarkDocumentsAsRead = async (selectedDocs: Array<SelectedDoc>) => {
    const viewed = new Date().toISOString()

    const onlyUnreads = selectedDocs.filter((selectedDoc) => {
      return unreadDocuments.findIndex(unreadDoc => unreadDoc.documentId === selectedDoc.id) >= 0
    })

    try {
      await Promise.all(
        onlyUnreads.map( async doc => {
          return await markDealDocumentAsRead(clpId, doc.id, dealTenant.tenantName, viewed)
        })
      )
      markAsDocumentRead(onlyUnreads)
    } catch (e) {
      // TODO: Discuss, unsure what is best to do when unread fail to update, shouldn't really happen if everything else is work, but what if.
      console.log(e)
    }
  }

  const handleZipDownload = async () => {
    setLoadingZip(true)
    setZipError(false)

    try {
      const response = await getSignedZip(selectedDocs.map((doc) => doc.s3Path))
      const { signedUrl } = response.data

      window.open(signedUrl, '_blank')
      // TODO: Swap if to not onset when going to prod
      if (userContext?.attributes['custom:companyName'] !== 'onset') {
        await handleMarkDocumentsAsRead(selectedDocs)
      }
    } catch {
      setZipError(true)
    } finally {
      setLoadingZip(false)
    }
  }

  const rows = filteredDocuments.map(doc => {
    const hasUnreadDoc = unreadDocuments.filter(unreadDoc => doc.id === unreadDoc.documentId)
    return (
      <tr key={doc.id}>
        <td className={'align-middle'}>
          <Input
            checked={!!selectedDocs.find((selected) => selected.id === doc.id)}
            className={'m-0 position-relative'}
            onChange={(e) => handleCheck(e.target.checked, [{ id: doc.id, s3Path: doc.s3Path }])}
            type={'checkbox'} />
        </td>
        {
          hasUnreadDoc.length > 0
            ? <td className={'text-break position-relative'}><span className={'unread-light-icon'} />{doc.name}</td>
            : <td className={'text-break'}>{doc.name}</td>
        }
        <td>{DateUtils.formatDateFromStamp(doc.createdAt)}</td>
        <td>
          <DealSingleDocumentLink
            s3Path={doc.s3Path}
            clpId={clpId}
            dealTenant={dealTenant}
            documentId={doc.id}
            isUnread={hasUnreadDoc.length > 0}
            markAsDocumentRead={markAsDocumentRead}
            userCompany={userContext?.attributes['custom:companyName'] || null}
          />
        </td>
      </tr>
    )
  })

  const tableHeader = (
    <tr>
      <th className={'align-middle'}>
        <Input
          className={'m-0 position-relative'}
          onChange={(e) => handleCheck(e.target.checked, filteredDocuments.map((doc) => ({
            id: doc.id,
            s3Path: doc.s3Path
          })))}
          type={'checkbox'} />
      </th>
      <th>Name</th>
      <th>Uploaded</th>
      <th />
    </tr>
  )

  const onSubmit = (values: DefaultValues) => {
    const { documentType } = values
    const docTypeNumber = Number(documentType)

    let filtered: Array<DocumentBase> = []

    if (docTypeNumber === DocumentTypes.All) {
      filtered = documents
    } else if (docTypeNumber === DocumentTypes.Proposal) {
      filtered = documents.filter(doc => doc.isSignedProposal)
    } else if (docTypeNumber === DocumentTypes.Financials) {
      filtered = documents.filter(doc => doc.isFinancialStatement)
    } else if (docTypeNumber === DocumentTypes.Equipment) {
      filtered = documents.filter(doc => doc.isEquipmentConfiguration)
    } else {
      filtered = documents.filter(doc => (!doc.isSignedProposal && !doc.isFinancialStatement && !doc.isEquipmentConfiguration))
    }

    setFilteredDocuments(filtered)
  }

  const filter = (
    <form className={'form-inline w-100 mb-1'}>
      <div className={'d-flex justify-content-between align-items-center w-100 mb-2'}>
        <div className={'form-group ml-1'}>
          <label className={'mr-1'} htmlFor={'document-type'}>Document Type:</label>
          <select
            className={'custom-select'}
            onChange={handleSubmit(onSubmit)}
            name={'documentType'}
            id={'document-type'}
            ref={register}>
            {options}
          </select>
        </div>
        <div className={'form-group mr-3'}>
          {
            zipError && (
              <>
                <span className={'mr-1'} id={'ZipErrorTooltip'}>
                  <i className={'text-danger fas fa-exclamation-triangle fa-lg'} />
                </span>
                <UncontrolledTooltip autohide={false} placement={'top'} target={'ZipErrorTooltip'}>
                  <>
                    <p className="text-light mb-3">
                      There has been an issue with the file download, please try again or click the link below to send
                      an email to support.
                    </p>
                    <a className={'text-light mt-1'} href={'mailto:support@onsetfinancial.com'} target={'_blank'}
                      rel={'noreferrer'}><b>Contact Support</b></a>
                  </>
                </UncontrolledTooltip>
              </>
            )
          }
          <Button
            color={'info'}
            disabled={selectedEmpty || loadingZip}
            onClick={handleZipDownload}>
            {
              loadingZip && (
                <>
                  <span className="spinner-border spinner-border-sm mr-2 loading-button-spinner" role="status"
                    aria-hidden="true" />
                  <span className="sr-only">Loading...</span>
                </>
              )
            }
            Download Selected
          </Button>
        </div>
      </div>
    </form>
  )

  return (
    <>
      <h5
        className={'mb-2 mt-3 text-uppercase bg-light p-2'}
        onClick={() => setIsExpanded(!isExpanded)}>
        Documents
        <i className={`float-right fas ${isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'} cursor-pointer-custom`} />
      </h5>
      <div className={`collapse ${isExpanded ? 'show' : ''}`}>
        <ResponsiveTable btnToolbar={filter} tableHeader={tableHeader} tableRows={rows} tableStriped />
      </div>
    </>
  )
}

export default DealSingleDocuments
