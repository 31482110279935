import React, { FunctionComponent } from 'react'
import { Col, Row } from 'reactstrap'
import { Comment } from '../../models/comment'
import CommentItem from './Item'
import CommentForm from './Form'

interface Props {
  canCreateComment: boolean
  clearSubmitError: () => void
  comments: Array<Comment>
  commentSubmit: (body: string) => void
  error: any | null
  isLoading: boolean
  refreshComments: () => void
  selfSub: string
  submitError: {
    message: string
    originalBody: string
  } | null
  submitIsSubmitting: boolean
}

const CommentContainer: FunctionComponent<Props> = (props) => {
  const { canCreateComment, clearSubmitError, comments, commentSubmit, error, isLoading, refreshComments, selfSub, submitError, submitIsSubmitting } = props

  if (isLoading) {
    return (
      <Row>
        <Col xs={12}>
          <div className={'d-flex justify-content-center'}>
            <div className="spinner-border avatar-lg text-primary m-2" role="status" />
          </div>
        </Col>
      </Row>
    )
  }

  if (error) {
    return (
      <Row>
        <Col xs={12}>
          <div className={'d-flex justify-content-center'}>
            <span className={'text-center'}>
              There has been an error loading Comments. Please try again later or
              <a href={'mailto:support@onsetfinancial.com'} target={'_blank'} rel={'noreferrer'}> Contact Support</a>.
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  const rows = (comments && comments.length > 0) && comments.map((comment, idx) => (
    <CommentItem
      key={idx}
      selfId={selfSub}
      {...comment}
    />
  ))

  return (
    <Row>
      <Col sm={12} className={'mb-2'}>
        <CommentForm
          canCreateComment={canCreateComment}
          clearSubmitError={clearSubmitError}
          commentSubmit={commentSubmit}
          submitIsSubmitting={submitIsSubmitting}
          submitError={submitError}
          refreshComments={refreshComments}
        />
      </Col>
      <Col sm={12}>
        <div className={'chat-conversation mb-2 row-chat-custom scrollbar-addition-dark'}>
          <ul className={'conversation-list slimscroll pl-0 pr-1'}>
            {rows}
          </ul>
        </div>
      </Col>
    </Row>
  )
}

export default CommentContainer
