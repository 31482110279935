interface SplitDate {
  ampm: string
  day: number
  hours: number
  minutes: number
  month: number
  year: number
}

const DateUtils = {
  formatDateFromStamp: function (inputDate: string) {
    if (inputDate) {
      const date = new Date(inputDate)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${month}/${day}/${year}`
    } else {
      return null
    }
  },

  getDateInfoFromStamp: function (inputDate: string): SplitDate | null {
    if (inputDate) {
      const date = new Date(inputDate)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      let hours = date.getHours()
      const minutes = date.getMinutes()
      const ampm = hours > 12 ? 'pm' : 'am'

      if (hours === 0) {
        hours = 12
      } else if (hours > 12) {
        hours = hours - 12
      }

      return { ampm, day, hours, minutes, month, year }
    } else {
      return null
    }
  }
}

export default DateUtils
